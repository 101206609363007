.app-pagination {
  li.page a.active {
    background-color: #2e55fa;
    color: #fff;
  }

  ul.pagination {
    padding: 0;
  }

  div.page-counter {
    margin-right: 1rem;
    font-family: montserrat, serif;
    font-size: 0.8rem;
    font-weight: 300;
  }
}

.app-pagination.center-align {
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .page-counter {
      margin-bottom: 1rem;
    }
  }
}