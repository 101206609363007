.browse-jobs {
  .info-row > div:not(:last-child) {
    padding-right: 1rem;
  }

  .info-row .salary-bx {
    margin-bottom: 1rem;
  }

  .job-find-box {
    box-sizing: border-box;
  }

  .reset-button {
    $color: #2e55fa;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    border: 2px solid $color;
    background-color: #fff;
    color: $color;
  }

  .pages-cont {
    margin-top: 2rem;

    .app-pagination {
    }
  }

  @media (max-width: 980px) {
    .jobs-cont, .accordion-cont {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .right-side-filter {
    .per-page-select {
      width: 200px;
    }

    .timeframe-select {
      margin-right: 1rem;
    }
  }
}