.overlay-loader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: inherit;
  z-index: 999;
}

.overlay-loader-inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.overlay-loader-content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overlay-loader-spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-width: 4px;
  border-color: #2196F3 #1E88E5 #1976D2 #1565C0;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}