.site-header {
  img.logo, img.explorejobsearch-logo {
    max-height: 60px;

    @media (max-width: 766px) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  img.logo {
    min-width: 280px;
  }
}