.homepage-jobs {
  .section-bottom {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      .pagination-cont {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .page-counter {
          margin-bottom: 1rem;
        }
      }
    }
    justify-content: space-between;

    .browse-btn {
      align-self: auto !important;
      @media (max-width: 768px) {
        align-self: center !important;
      }
    }
  }

  .info-row > div:not(:last-child) {
    padding-right: 1rem;
  }

  .info-row .salary-bx {
  }

  .post-bx {
    padding: 25px 25px 1px;

    .job-post-info > h4:first-child {
      font-size: 1.3rem;
    }
  }
}

.content-inner-2.homepage-jobs {
  padding-bottom: 40px;
}